<template>
  <div class="main-doc">
    <div class="_container">
      <div class="main-doc__title">Контактные данные и реквизиты</div>
      <div class="main-doc__body">
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">
            Региональная молодёжная общественная организация «Лига студентов
            Республики Татарстан»
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Юридический адрес</div>
          <div class="main-doc__content">
            420021, Республика Татарстан, г. Казань, ул. Габдуллы Тукая, д. 58
            оф. 303
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Фактический адрес</div>
          <div class="main-doc__content">
            420021, Республика Татарстан, г. Казань, ул. Габдуллы Тукая, д. 58
            оф. 303
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Реквизиты</div>
          <div class="main-doc__content">
            <p>РМОО «Лига студентов Республики Татарстан»</p>
            <p>ИНН/КПП 1654026665 / 165501001</p>
            <p>р/сч 40703810945020000060 в ПАО «АК БАРС» БАНК г. Казань</p>
            <p>к/сч 30101810000000000805 БИК 049205805</p>
            <p>ОКПО — 43825610</p>
            <p>ОКОНХ — 98600, 98400</p>
            <p>СООГУ — 07994</p>
            <p>СОАТО — 1192401367</p>
            <p>КФС — 15</p>
            <p>КОПФ — 83</p>
            <p>ОКВЭД — 91.33</p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Вид деятельности</div>
          <div class="main-doc__content">
            Деятельность прочих общественных организаций, не включённых в другие
            группировки.
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Налоговый режим:</div>
          <div class="main-doc__content">
            <p>
              УСНО-доходы (на основании информационного письма (Форма № 26.2–7)
              от 13.11.2010 ФНС № 14 по РТ)
            </p>
            <p>Не являемся плательщиками НДС, работаем без счёт-фактур.</p>
            <p>ОГРН — 1021600005701</p>
            <p>Свидетельство о регистрации 195 от 30 мая 1996 г.</p>
            <p>Тел/факс 231–81–41</p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">
            Электронная почта:
            <a href="mailto:ligatatarstan@gmail.com">ligatatarstan@gmail.com</a>
          </div>
          <div class="main-doc__content">
            <ul>
              <li>
                7.1. Цель обработки персональных данных Пользователя:
                <ul>
                  <li>
                    — информирование Пользователя посредством отправки
                    электронных писем;
                  </li>
                  <li>
                    — заключение, исполнение и прекращение гражданско-правовых
                    договоров;
                  </li>
                  <li>
                    — предоставление доступа Пользователю к сервисам, информации
                    и/или материалам, содержащимся на веб-сайте
                    <a href="https://Ligastudentov.com" target="_blank">
                      ligastudentov.com
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                7.2. Также Оператор имеет право направлять Пользователю
                уведомления о новых продуктах и услугах, специальных
                предложениях и различных событиях. Пользователь всегда может
                отказаться от получения информационных сообщений, направив
                Оператору письмо на адрес электронной почты
                ligatatarstan@gmail.com с пометкой «Отказ от уведомлений о новых
                продуктах и услугах и специальных предложениях».
              </li>
              <li>
                7.3. Обезличенные данные Пользователей, собираемые с помощью
                сервисов интернет-статистики, служат для сбора информации о
                действиях Пользователей на сайте, улучшения качества сайта и его
                содержания.
              </li>
            </ul>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Президент</div>
          <div class="main-doc__content">Якупов Шамиль Наилевич</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Requisites",
};
</script>

<style scoped lang="scss"></style>
